import "@mantine/core/styles.css";
import React from "react";
import { MantineProvider, createTheme } from "@mantine/core";

const theme = createTheme({
    fontFamily: "Noto Sans",
});

export const wrapPageElement = ({ element }) => {
    return <MantineProvider theme={theme}>{element}</MantineProvider>;
};